<template>
  <b-card-code title="微信列表">
    <!-- search input -->

    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-login
      variant="outline-primary"
    >
      添加员工
    </b-button>

    <b-modal
      ref="modal-login"
      id="modal-login"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="添加员工"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-form-group label="帐号" label-for="login-email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              vid="email"
              rules="required"
            >
              <b-form-input
                id="login-email"
                v-model="account"
                :state="errors.length > 0 ? false : null"
                name="login-email"
                placeholder="Xxx"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="password">密码:</label>
            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="Password"
              rules="required|min:6"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="a-password"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  placeholder="******"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label for="module">确认密码:</label>
            <validation-provider
              #default="{ errors }"
              name="ConfirmPassword"
              vid="ConfirmPassword"
              rules="required|confirmed:Password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="Confirm-password"
                  v-model="passValue"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="Confirm-password"
                  placeholder="******"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label for="name">姓名:</label>
            <b-form-input id="name" placeholder="请输入姓名" v-model="name" />
          </b-form-group>
          <b-form-group label="上级部门" label-for="dname">
            <b-form-select
              v-model="roleSelect"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-dname"
            />
          </b-form-group>
          <b-form-group>
            <label for="status">状态:</label>
            <b-form-radio
              v-model="states"
              name="some-radio9"
              value="1"
              class="custom-control-success"
            >
              启用
            </b-form-radio>
            <b-form-radio
              v-model="states"
              name="some-radio9"
              value="0"
              class="custom-control-danger"
            >
              停用
            </b-form-radio>
          </b-form-group>
          <div align="right">
            <b-button variant="relief-success" @click="validationForm">
              提交
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="搜索"
            type="text"
            class="d-inline-block"
            @input="handleSearch"
          />
        </div>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      :pagination-options="{
        enabled: true,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->

        <span v-if="props.column.field === 'type'">
          {{ props.row.type == "0" ? "管理员" : "客服" }}
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'state'">
          {{ props.row.state == "1" ? "启用" : "停用" }}
        </span>
        <span v-else-if="props.column.field === 'createTime'">
          {{ timestampToTime(props.row.createTime) }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="golink(props.row.id)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>编辑</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deletebyid(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>删除</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> 显示 </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> {{ total }} 条 </span>
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              :value="1"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BFormRadio,
  BButton,
  BPagination,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BForm,
  BModal,
  BFormCheckbox,
  VBModal,
  BFormTextarea,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";

import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { required, min, confirmed } from "@validations";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BFormRadio,
    BCardCode,
    BFormTextarea,
    VueGoodTable,
    BFormCheckbox,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    vSelect,
    BForm,
    BModal,
    VBModal,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BFormValidFeedback,
  },
  mixins: [togglePasswordVisibility],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      total: 0,
      cid: 0,
      desc: "",
      name: "",
      remark: "",
      Selected: "",
      password: "",
      passValue: "",
      states: "",
      account: "",
      search: "",
      roleSelect: "",
      roleOptions: [],

      pages: ["10", "20", "40", "100"],
      columns: [
        {
          label: "帐号",
          field: "account",
        },
        {
          label: "姓名",
          field: "nickname",
        },
        {
          label: "类型",
          field: "type",
        },
        {
          label: "部门",
          field: "dname",
        },
        {
          label: "状态",
          field: "state",
        },
        {
          label: "操作",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    state() {
      return Boolean(this.value);
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: "light-success",
        1: "light-danger",

        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    useJwt
      .queryAccountsbyCid({
        cid: this.cid,
        page: 1,
        rows: this.pageLength,
        desc: this.desc,
      })
      .then((res) => {
        // pagelength 一页显示多少条
        this.total = res.data.data.length;
        this.rows = res.data.data;
      });
  },
  methods: {
    timestampToTime(cjsj) {
      const date = new Date(cjsj); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      const Y = `${date.getFullYear()}-`;
      const M = `${
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1
      }-`;
      const D = `${date.getDate()} `;
      const h = `${date.getHours()}:`;
      const m = `${date.getMinutes()}:`;
      const s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          useJwt
            .addaccount({
              cid: this.cid,
              account: this.account,
              password: this.passValue,
              did: this.roleSelect,
              nickname: this.name,
              state: this.states,
            })
            .then((res) => {
              if (res.data.msg == "success") {
                this.$swal({
                  icon: "success",
                  title: "员工添加成功!",
                  text: "员工添加成功",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                this.account = "";
                this.passValue = "";
                this.roleSelect = "";
                this.name = "";
                this.states = "";
                this.$refs["modal-login"].hide();
              }
            });
        } else {
          this.$swal({
            icon: "error",
            title: "检查输入的是否错误!",
            text: "输入错误",
            customClass: {
              confirmButton: "btn btn-error",
            },
          });
        }
      });
    },
    deletebyid(id) {
      this.$swal({
        title: "确定要删除吗?",
        text: "删除后无法恢复!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          useJwt.delAccountsbyid({ ids: id }).then((res) => {
            if (res.data.code === 0) {
              this.$swal({
                icon: "success",
                title: "删除!",
                text: "删除成功",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              useJwt
                .queryAccountsbyCid({
                  cid: this.cid,
                  page: 1,
                  rows: this.pageLength,
                })
                .then((res) => {
                  this.total = res.data.data.length;
                  this.rows = res.data.data;
                });
            } else {
              this.$swal({
                icon: "error",
                title: "删除失败!",
                text: `${res.data.msg}`,
                customClass: {
                  confirmButton: "btn btn-error",
                },
              });
            }
          });
        }
      });
    },

    handleSearch(searching) {
      useJwt
        .querydepartmentbycid({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          name: searching,
        })
        .then((res) => {
          this.total = res.data.data.length;
          this.rows = res.data.data;
        });
      this.search = searching;
    },
    handleChangePage(page) {
      useJwt
        .querydepartmentbycid({
          cid: this.cid,
          page: page,
          rows: this.pageLength,
          desc: this.desc,
          name: this.search,
        })
        .then((res) => {
          this.total = res.data.data.length;
          this.rows = res.data.data;
        });
    },
    handlePageChange(active) {
      useJwt
        .querydepartmentbycid({
          cid: this.cid,
          page: 1,
          rows: active,
          desc: this.desc,
          name: this.search,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          this.pageLength = active;
          this.total = res.data.data.length;
          this.rows = res.data.data;
        });
    },
    onSortChange(params) {
      this.desc = `${params[0].field}`;
      if (this.desc == "createTime") {
        this.desc = "create_time" + ` ${params[0].type}`;
      } else {
        this.desc = `${params[0].field} ${params[0].type}`;
      }

      useJwt
        .querydepartmentbycid({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
          name: this.search,
        })
        .then((res) => {
          this.total = res.data.data.length;
          this.rows = res.data.data;
        });
    },
  },
  mounted() {
    useJwt.queryByCid({ cid: this.cid }).then((response) => {
      if (response.data.code == 0) {
        const forArr = response.data.data;

        forArr.forEach((item, i) => {
          this.roleOptions.push({ text: item.name, value: item.id });
        });
      }
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/pages/page-auth.scss";
</style>
