import { render, staticRenderFns } from "./staffmanger.vue?vue&type=template&id=2f7c2edf&"
import script from "./staffmanger.vue?vue&type=script&lang=js&"
export * from "./staffmanger.vue?vue&type=script&lang=js&"
import style0 from "./staffmanger.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports